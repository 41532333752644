import * as React from 'react';
import { Text, TextField, DefaultButton, Checkbox } from '@fluentui/react';
import { APIFetchingButton } from './FetchingButton';
import { NotificationContext } from './contexts/NotificationContext';
import { useHistory } from 'react-router-dom';
import { withAPIConnection } from './containers/withAPIConnection';

export function ProductEditForm(props) {
  const { product } = props;
  const [name, setName] = React.useState('');
  const [sku, setSku] = React.useState('');
  const [series, setSeries] = React.useState('');
  const [dependency, setDependency] = React.useState('');
  const [installationPath, setInstallationPath] = React.useState('');
  const [installDir, setInstallDir] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState('');
  const [moodshotUrl, setMoodshotUrl] = React.useState('');
  const [logoUrl, setLogoUrl] = React.useState('');
  const [tagLine, setTagLine] = React.useState('');
  const [manualUrl, setManualUrl] = React.useState('');
  const [detailsUrl, setDetailsUrl] = React.useState('');
  const [hidden, setHidden] = React.useState('');
  const [channel, setChannel] = React.useState('');
  const [free, setFree] = React.useState(false);
  const [trialDays, setTrialDays] = React.useState(7);
  const [featured, setFeatured] = React.useState(false);
  const [featuredSortOrder, setFeaturedSortOrder] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState('');
  const [appVersionMin, setAppVersionMin] = React.useState('');
  const [appVersionMax, setAppVersionMax] = React.useState('');
  const [lastFreeVersion, setLastFreeVersion] = React.useState('');
  const [showOnlyIfOwned, setShowOnlyIfOwned] = React.useState(false);
  const history = useHistory();
  const notify = React.useContext(NotificationContext);

  React.useEffect(() => {
    if (!product) {
      return;
    }
    setName(product.name);
    setSku(product.sku);
    setSeries(product.series);
    setDependency(product.dependency);
    setInstallationPath(product.installationPath);
    setInstallDir(product.installDir);
    setImageUrl(product.imageUrl);
    setMoodshotUrl(product.moodshotUrl);
    setLogoUrl(product.logoUrl);
    setFree(product.free);
    setTrialDays(product.trialDays);
    setFeatured(product.featured);
    setFeaturedSortOrder(product.featuredSortOrder);
    setTagLine(product.tagLine);
    setManualUrl(product.manualUrl);
    setDetailsUrl(product.detailsUrl);
    setHidden(product.hidden);
    setChannel(product.channel);
    setSortOrder(product.sortOrder);
    setAppVersionMin(product.appVersionMin);
    setAppVersionMax(product.appVersionMax);
    setLastFreeVersion(product.lastFreeVersion);
    setShowOnlyIfOwned(product.showOnlyIfOwned);
  }, [product]);

  const styles = {
    padding: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  };
  const headingStyles = {
    flex: '1 0 100%',
  };
  const bodyStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  };
  const textFieldStyles = {
    root: {
      width: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
  };
  const checkboxStyles = {
    root: {
      flex: '1 0 100%',
      marginTop: '1rem',
    },
  };
  const buttonStyles = {
    root: {
      marginRight: '1rem',
      marginTop: '3rem',
    },
  };
  const containerDivStyles = {
    root: {
      flex: '1 0 100%',
    },
  };
  const buttonContainerDivStyles = {
    root: {
      flex: '1 1 100%',
    },
  };
  const deleteButtonStyles = {
    root: {
      color: 'red',
      backgroundColor: 'white',
      marginLeft: '8rem', // TODO will probably never learn how this is to be done
      // position: 'absolute',
      // right: '2.5rem',
      // bottom: '12.5rem',
    },
    rootHovered: { background: 'red' },
  };
  const saveButtonDisabled = !(name && name.length && sku && sku.length);
  const handleError = ({ error, status, statusText }) => {
    notify.error({ message: `Failed to update ${product.name}. ${error ?? 'Unknown Error'}. ${status ? `${status} (${statusText}).` : ''}` });
  };
  const handleSuccess = () => {
    props.onProductModified();
    history.goBack();
  };
  const handleSuccessDelete = () => {
    console.debug('product deleted');
    props.onProductDeleted();
    history.replace(`/protected/products`);
  };
  const handleErrorDelete = ({ error, status, statusText }) => {
    notify.error({ message: `Failed to delete ${product.name}. ${error ?? 'Unknown Error'}. ${status ? `${status} (${statusText}).` : ''}` });
  };

  return (
    <div style={styles}>
      <Text style={headingStyles} variant={'large'}>
        Edit {(product && product.sku) ?? 'product'}
      </Text>
      <div style={bodyStyles}>
        {product && (
          <>
            <div style={containerDivStyles}></div>
            <TextField value={name} onChange={e => setName(e.target.value)} label="Name" styles={textFieldStyles} required placeholder={'TITLENAME'} />
            <TextField value={sku} onChange={e => setSku(e.target.value)} label="SKU" styles={textFieldStyles} required placeholder={'VI-TITLESKU'} />
            <TextField value={series} onChange={e => setSeries(e.target.value)} label="Series" styles={textFieldStyles} required placeholder={'SERIES'} />
            <TextField value={dependency} onChange={e => setDependency(e.target.value)} label="Dependency" styles={textFieldStyles} placeholder={''} />
            <TextField value={installationPath} onChange={e => setInstallationPath(e.target.value)} label="Install Path Base" styles={textFieldStyles} placeholder={'SHARED_USER/UJAM/Beatmaker/'} />
            <TextField value={installDir} onChange={e => setInstallDir(e.target.value)} label="Install Directory" styles={textFieldStyles} placeholder={''} />
            <TextField value={tagLine} onChange={e => setTagLine(e.target.value)} label="Tagline" styles={textFieldStyles} placeholder={'...'} />
            <TextField value={imageUrl} onChange={e => setImageUrl(e.target.value)} label="Image URL" styles={textFieldStyles} placeholder={'...'} />
            <TextField value={moodshotUrl} onChange={e => setMoodshotUrl(e.target.value)} label="Moodshot URL" styles={textFieldStyles} placeholder={'...'} />
            <TextField value={logoUrl} onChange={e => setLogoUrl(e.target.value)} label="Logo URL" styles={textFieldStyles} placeholder={'...'} />
            <TextField value={manualUrl} onChange={e => setManualUrl(e.target.value)} label="Manual URL" styles={textFieldStyles} placeholder={'...'} />
            <TextField value={detailsUrl} onChange={e => setDetailsUrl(e.target.value)} label="Details URL" styles={textFieldStyles} placeholder={'...'} />
            <TextField value={sortOrder} onChange={e => setSortOrder(e.target.value)} label="Sort order" styles={textFieldStyles} placeholder={'0'} />
            <TextField
              value={featuredSortOrder}
              onChange={e => setFeaturedSortOrder(e.target.value)}
              label="Featured sort order"
              styles={textFieldStyles}
              placeholder={'0'}
            />
            <TextField value={trialDays} onChange={e => setTrialDays(e.target.value)} label="Trial Days" styles={textFieldStyles} placeholder={'30'} />
            <TextField
              value={appVersionMin}
              onChange={e => setAppVersionMin(e.target.value)}
              label="AppVersion min"
              styles={textFieldStyles}
              placeholder={''}
            />
            <TextField
              value={appVersionMax}
              onChange={e => setAppVersionMax(e.target.value)}
              label="AppVersion max"
              styles={textFieldStyles}
              placeholder={''}
            />
            <TextField
              value={lastFreeVersion}
              onChange={e => setLastFreeVersion(e.target.value)}
              label="Last free Version (frame)"
              styles={textFieldStyles}
              placeholder={''}
            />
            <TextField
              value={channel}
              onChange={e => setChannel(e.target.value)}
              label="Channel Restriction"
              styles={textFieldStyles}
              placeholder={'beta or alpha'}
            />
            <Checkbox checked={free} onChange={e => setFree(e.target.checked)} label="Free Product" styles={checkboxStyles} />
            <Checkbox checked={featured} onChange={e => setFeatured(e.target.checked)} label="Featured Product" styles={checkboxStyles} />
            <Checkbox checked={showOnlyIfOwned} onChange={e => setShowOnlyIfOwned(e.target.checked)} label="Show only if owned" styles={checkboxStyles} />
            <Checkbox checked={hidden} onChange={e => setHidden(e.target.checked)} label="Hidden" styles={checkboxStyles} />
          </>
        )}
      </div>
      <div style={buttonContainerDivStyles}>
        <DefaultButton
          onClick={() => {
            history.goBack();
          }}
          styles={buttonStyles}
        >
          <Text>Cancel</Text>
        </DefaultButton>
        <APIFetchingButton
          text={'Save'}
          styles={buttonStyles}
          onGetData={() => ({
            name,
            sku,
            series,
            dependency,
            installationPath,
            installDir,
            tagLine,
            imageUrl,
            moodshotUrl,
            logoUrl,
            free,
            trialDays,
            featured,
            featuredSortOrder,
            manualUrl,
            detailsUrl,
            hidden,
            channel,
            sortOrder,
            appVersionMin,
            appVersionMax,
            lastFreeVersion,
            showOnlyIfOwned,
          })}
          method={'PUT'}
          onGetRoute={() => `products/${product.id}`}
          onSuccess={handleSuccess}
          onError={handleError}
          disabled={saveButtonDisabled}
        />
        <APIFetchingButton
          text={'Delete'}
          styles={deleteButtonStyles}
          onGetData={() => console.debug('Product delete, onGetData -> dono')}
          method={'DELETE'}
          onGetRoute={() => `products/${product.id}`}
          onSuccess={handleSuccessDelete}
          onError={handleErrorDelete}
        />
      </div>
    </div>
  );
}

export const ProductEditFormWithAPIConnection = withAPIConnection(
  // wrapped component
  ProductEditForm,

  // initial data
  {
    product: null,
  },

  // get route and params callback
  (state, props) => ({
    route: `products/${props.productId}`,
  }),

  //select data callback
  (data, state, props) => ({
    product: data,
  }),

  // should refetch callback
  (prevState, state, prevProps, props) => props.productId !== prevProps.productId
);
